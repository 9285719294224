import { pxToRem } from "_utils/pxToRem";
import { LayoutGridSpace } from "presentation/style/shared";
import { breakpoints, colors } from "presentation/style/theme";
import styled from "styled-components";

export const ContainerProduction = styled.section`
  ${LayoutGridSpace};
  width: 100%;
  row-gap: 24px;
  .production__title {
    font-weight: 400;
    color: ${colors.neutral[900]};
    line-height: ${pxToRem(24)};
  }
  .production__item {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    .item__title {
      font-weight: 600;
      color: ${colors.neutral[900]};
      line-height: ${pxToRem(24)};
    }
    .item__content {
      color: ${colors.neutral[900]};
      font-size: ${pxToRem(16)};
      line-height: ${pxToRem(24)};
    }
    .item__content--line {
      display: flex;
      border-bottom: 1px solid ${colors.neutral[900]};
      padding: 8px;
      span:first-child {
        width: 50%;
        max-width: 50%;
      }
    }
    .item__small {
      color: ${colors.neutral[700]};
      font-size: ${pxToRem(10)};
      line-height: ${pxToRem(10)};
    }
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    .production__title {
      font-size: ${pxToRem(20.25)};
      grid-column: 1 / span 2;
    }
    .production__item {
      grid-column: 1 / span 2;
      .item__title {
        font-size: ${pxToRem(18)};
      }
    }
    .production__item--volume {
      grid-row: 4 / span 1;
    }
    .production__item--metodo {
      grid-row: 5 / span 1;
    }
    .production__item--prazo {
      grid-row: 6 / span 1;
    }
    .production__item--realizacao {
      grid-row: 7 / span 1;
    }
  }
  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
    .production__title {
      font-size: ${pxToRem(23.04)};
      grid-column: 1 / span 2;
    }
    .production__item {
      .item__title {
        font-size: ${pxToRem(18)};
      }
    }
    .production__item--material,
    .production__item--volume,
    .production__item--prazo {
      grid-column: 1 / span 1;
    }
    .production__item--volume {
      grid-row: 3 / span 1;
    }
    .production__item--prazo {
      grid-row: 4 / span 1;
    }

    .production__item--temperatura,
    .production__item--estabilidade {
      grid-column: 1 / span 2;
    }

    .production__item--meios,
    .production__item--metodo,
    .production__item--realizacao {
      grid-column: 2 / span 1;
    }
    .production__item--metodo {
      grid-row: 3 / span 1;
    }
    .production__item--realizacao {
      grid-row: 4 / span 1;
    }
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    grid-template-columns: repeat(8, 1fr);
    .production__title {
      font-size: ${pxToRem(23.04)};
      grid-column: 1 / span 8;
    }
    .production__item {
      .item__title {
        font-size: ${pxToRem(19.2)};
      }
    }
    .production__item--material,
    .production__item--temperatura,
    .production__item--volume,
    .production__item--prazo {
      grid-column: 1 / span 4;
    }
    .production__item--metodo,
    .production__item--volume {
      grid-row: 3 / span 1;
    }
    .production__item--realizacao,
    .production__item--prazo {
      grid-row: 4 / span 1;
    }

    .production__item--meios,
    .production__item--metodo,
    .production__item--estabilidade,
    .production__item--realizacao {
      grid-column: 5 / span 4;
    }
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    grid-template-columns: repeat(8, 1fr);
    .production__title {
      font-size: ${pxToRem(25)};
      line-height: ${pxToRem(32)};
      grid-column: 1 / span 8;
    }
    .production__item {
      .item__title {
        font-size: ${pxToRem(20)};
      }
    }
    .production__item--material,
    .production__item--volume,
    .production__item--prazo {
      grid-column: 1 / span 2;
    }
    .production__item--meios,
    .production__item--metodo,
    .production__item--realizacao {
      grid-column: 3 / span 2;
    }
    .production__item--temperatura,
    .production__item--estabilidade {
      grid-column: 5 / span 2;
    }
    .production__item--estabilidade {
      grid-row: 3 / span 2;
    }
  }
`;

import styled from "styled-components";
import { breakpoints, colors } from "presentation/style/theme";
import { LayoutGrid } from "presentation/style/shared";
import { pxToRem } from "_utils/pxToRem";

export const RootInternalExam = styled.section`
  .cta__section {
    margin-top: 160px;
  }
`;

export const InternaExamContainer = styled.div`
  margin-top: 0;
  .exam__breakpage {
    ${LayoutGrid};
    margin-top: 80px;
    .exam__anchors {
      display: flex;
      flex-direction: column;
      row-gap: 32px;
      height: fit-content;
      .anchor__item {
        text-decoration: none;
        color: ${colors.neutral[900]};
        font-size: ${pxToRem(13.13)};
        line-height: ${pxToRem(16)};
        width: fit-content;
        transition: all 0.2s;
        :hover {
          font-weight: 600;
        }
      }
      .anchor__item--selected {
        font-weight: 600;
      }
    }
    .exam__slice {
      display: flex;
      flex-direction: column;
      row-gap: 80px;
      .divider__line {
        border: none;
        width: 100%;
        height: 1px;
        background-color: ${colors.neutral[900]};
        :last-child {
          display: none;
        }
      }
    }
  }
  @media screen and (min-width: ${breakpoints.md}) {
    .exam__breakpage .exam__anchors {
      position: sticky;
      top: 290px;
    }
  }
  @media screen and (max-width: 767px) {
    .exam__breakpage {
      margin-top: 40px;
      .exam__anchors {
        row-gap: 16px;
        align-items: center;
        grid-column: 1 / span 2;
      }
      .exam__slice {
        grid-column: 1 / span 2;
        margin-top: 40px;
      }
    }
  }
  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    .exam__breakpage {
      .exam__anchors {
        grid-column: 2 / span 1;
        padding-top: 8px;
      }
      .exam__slice {
        grid-column: 3 / span 5;
      }
    }
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    .exam__breakpage {
      .exam__anchors {
        grid-column: 2 / span 2;
        padding-top: 8px;
      }
      .exam__slice {
        grid-column: 4 / span 8;
      }
    }
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    .exam__breakpage {
      margin-top: 80px;
      .exam__anchors {
        grid-column: 2 / span 1;
      }
      .exam__slice {
        grid-column: 3 / span 9;
      }
    }
  }
`;

import React from "react";
import { DownloadIcon } from "presentation/assets/icons";
import { INamesSlice, sliceDocumentos } from "_types/IExameInterno";
import { ContainerDocuments } from "./style";

interface DocumentsProps {
  data: sliceDocumentos;
  handleHoverSection: ({
    slice,
    action
  }: {
    slice: INamesSlice;
    action: "enter" | "exit";
  }) => void;
}

export function Documents({
  data,
  handleHoverSection
}: DocumentsProps): JSX.Element {
  return (
    <ContainerDocuments
      id="documentos"
      onMouseEnter={() =>
        handleHoverSection({ slice: "#documentos", action: "enter" })
      }
      onMouseLeave={() =>
        handleHoverSection({ slice: "#documentos", action: "exit" })
      }
    >
      <h3 className="document__title">Documentos para download</h3>
      <div className="document__item">
        <p className="item__name item__name--title">Documento</p>
        <p className="item__code item__code--title">Código</p>
      </div>
      {(data.items ?? []).map((item, index) => (
        <div
          key={Number(index)}
          className="document__item document__item--link"
        >
          <p className="item__name">
            {item.selecao_do_documento.document.data.nome_do_documento.text ??
              "-"}
          </p>
          <p className="item__code">
            {item.selecao_do_documento.document.data.codigo_do_documento.text ??
              "-"}
          </p>
          <a
            href={item.selecao_do_documento.document.data.anexo_documento.url}
            className="item__circle-icon"
            target="_blank"
            download
            rel="noreferrer"
          >
            <DownloadIcon className="item__icon" />
          </a>
        </div>
      ))}
    </ContainerDocuments>
  );
}

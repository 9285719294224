import { pxToRem } from "_utils/pxToRem";
import { LayoutGridSpace } from "presentation/style/shared";
import { breakpoints, colors } from "presentation/style/theme";
import styled from "styled-components";

export const ContainerInterpretation = styled.section`
  ${LayoutGridSpace};
  width: 100%;
  .interpretation__title {
    color: ${colors.neutral[900]};
    font-weight: 400;
    margin-bottom: 24px;
  }
  > .interpretation__text {
    color: ${colors.neutral[700]};
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    font-weight: 400;
  }
  .interpretation__relations {
    border-top: 1px solid ${colors.neutral[900]};
    border-bottom: 1px solid ${colors.neutral[900]};
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 40px;
    .relation__title {
      width: 100%;
    }
    .relation__title,
    > .relation__item {
      color: ${colors.neutral[900]};
      font-size: ${pxToRem(16)};
      line-height: ${pxToRem(24)};
      width: fit-content;
    }
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    .interpretation__title,
    > .interpretation__text,
    .interpretation__relations {
      grid-column: 1 / span 2;
    }
    .interpretation__title {
      font-size: ${pxToRem(20.25)};
      line-height: ${pxToRem(24)};
    }
  }
  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    grid-template-columns: repeat(5, 1fr);
    .interpretation__title {
      grid-column: 1 / span 5;
      font-size: ${pxToRem(23.04)};
      line-height: ${pxToRem(24)};
    }
    > .interpretation__text,
    .interpretation__relations {
      grid-column: 1 / span 5;
    }
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    grid-template-columns: repeat(8, 1fr);
    .interpretation__title {
      grid-column: 1 / span 8;
      font-size: ${pxToRem(23.04)};
      line-height: ${pxToRem(24)};
    }
    > .interpretation__text {
      grid-column: 1 / span 8;
    }
    .interpretation__relations {
      grid-column: 1 / span 8;
    }
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    grid-template-columns: repeat(8, 1fr);
    .interpretation__title {
      grid-column: 1 / span 8;
      font-size: ${pxToRem(25)};
      line-height: ${pxToRem(32)};
    }
    > .interpretation__text {
      grid-column: 1 / span 8;
    }
    .interpretation__relations {
      grid-column: 1 / span 8;
    }
  }
`;

import React from "react";
import { graphql } from "gatsby";
import { ThemeProvider } from "presentation/Layout/ThemeProvider";
import { TemplatePage } from "presentation/Layout/TemplatePage";
import InternalExams from "presentation/screens/InternalExams";
import {
  getDataCTAContato,
  getDataFooter,
  getDataMenu,
  getDataSearchMenu,
  getDataSectionQualityWarranty
} from "_utils/assemblePagesData";
import { ExameInternoProps } from "_types/IExameInterno";
import { WEBSITE_URL } from "_utils/constants";
import SEO from "presentation/Seo";

export default function ExameInterno({ data, location }: ExameInternoProps) {
  const isExistSpecialties =
    data?.specialties?.nodes && data?.specialties?.nodes.length > 0;
  const specialties = isExistSpecialties
    ? data?.specialties.nodes.map(item => ({
        label: item?.data?.nome_da_especialidade.richText[0].text,
        icon: item?.data?.icone_da_especialidade?.url,
        url: item?.url
      }))
    : [];

  const { menu, title, urlKnowMore } = getDataMenu({
    data: data?.menu?.data?.body
  });

  const searchMenu = getDataSearchMenu({
    searchExams: data?.searchExams,
    ctaContato: data?.ctaContato,
    allExams: data?.allExams,
    urlSearchExam: menu?.link_busca_exames?.uid
  });

  const footerData = getDataFooter({ data: data?.menu?.data?.body });
  const ctaContato = getDataCTAContato({ ctaContato: data?.ctaContato });
  const qualityWarranty = getDataSectionQualityWarranty({
    sectionQuality: data?.sectionQuality
  });

  const filteredData = data?.specialties.nodes.filter(item =>
    (item.data.body ?? []).some(bodyItem =>
      (bodyItem.items ?? []).some(
        itemSome =>
          itemSome.seletor_de_exames.document &&
          itemSome.seletor_de_exames.document.uid === data.prismicExames.uid
      )
    )
  );

  const topDataSpecialties = filteredData.map(item => {
    return {
      nome_da_especialidade: item.data.nome_da_especialidade.text,
      icone_da_especialidade: item.data.icone_da_especialidade.url
    };
  });

  const dataSEO = {
    canonicalUrl: `${WEBSITE_URL}${data?.prismicExames?.data?.canonical_url?.text}`,
    noFollow: data?.prismicExames?.data?.no_follow,
    noIndex: data?.prismicExames?.data?.no_index,
    openGraph: data?.prismicExames?.data?.open_graph[0],
    telefone1: data?.contact?.data?.telefone_1?.text,
    email: data?.contact?.data?.email?.text,
    imagesPage: [],
    metaTags: [
      {
        property: "application-name",
        content: "GatsbySeo"
      },
      {
        property: "og:title",
        content:
          data?.prismicExames?.data?.open_graph[0]?.og_title?.text ??
          "sem conteúdo"
      },
      {
        property: "og:url",
        content: `${WEBSITE_URL}${data?.prismicExames?.uid}`
      },
      {
        property: "og:image",
        content: data?.prismicExames?.data?.open_graph[0]?.og_image?.url
      },
      {
        property: "og:type",
        content: "website"
      }
    ]
  };

  return (
    <ThemeProvider>
      <SEO {...dataSEO} />
      <TemplatePage
        menu={menu}
        title={title}
        urlKnowMore={urlKnowMore}
        specialties={specialties}
        searchExams={searchMenu}
        footerData={footerData}
        contact={data?.contact}
      >
        <InternalExams
          dataPage={data.prismicExames}
          topDataSpecialties={topDataSpecialties}
          urlViewAll={searchMenu?.urlSearchExam}
          ctaContato={ctaContato}
          sectionQualityWarranty={qualityWarranty}
          location={location}
        />
      </TemplatePage>
    </ThemeProvider>
  );
}

export const query = graphql`
  query GetDataPageInternalExams($id: String) {
    menu: prismicMenuFooter {
      ...MenuFragment
    }
    contact: prismicContato {
      ...Contact
    }
    searchExams: prismicBuscarExames {
      ...BuscarExamesFragment
    }
    sectionQuality: prismicQualidade {
      ...QualityFragment
    }
    allExams: allPrismicExames {
      nodes {
        data {
          body {
            ... on PrismicExamesDataBodyHero {
              id
              items {
                sinonimo {
                  text
                }
              }
              primary {
                codigo_sollutio {
                  text
                }
                nome_do_exame {
                  text
                }
                codigo_tuss {
                  text
                }
              }
            }
          }
        }
        uid
      }
    }
    ctaContato: prismicCtaContato {
      ...CTAContatoFragment
    }
    specialties: allPrismicEspecialidade(
      sort: { order: ASC, fields: data___nome_da_especialidade___text }
    ) {
      nodes {
        data {
          body {
            ... on PrismicEspecialidadeDataBodySecaoDeExames {
              id
              slice_type
              items {
                seletor_de_exames {
                  document {
                    ... on PrismicExames {
                      uid
                    }
                  }
                }
              }
            }
          }
          nome_da_especialidade {
            richText
            text
          }
          icone_da_especialidade {
            url
          }
        }
        url: uid
      }
    }
    prismicExames(id: { eq: $id }) {
      uid
      data {
        no_follow
        no_index
        open_graph {
          og_url {
            text
          }
          og_title {
            text
          }
          og_image {
            url
          }
          og_description {
            text
          }
        }
        body {
          ... on PrismicExamesDataBodyHero {
            id
            items {
              sinonimo {
                text
              }
            }
            primary {
              nome_do_exame {
                text
              }
              codigo_tuss {
                text
              }
              codigo_sollutio {
                text
              }
              informacoes_do_exame {
                url
                uid
              }
              documento_mascara_do_laudo {
                uid
                url
              }
            }
            slice_type
          }
          ... on PrismicExamesDataBodySobre {
            slice_type
            primary {
              sobre_o_exame {
                richText
              }
            }
            items {
              doencas_relacionadas {
                text
              }
            }
          }
          ... on PrismicExamesDataBodyProducao {
            id
            slice_type
            primary {
              material1 {
                text
              }
              volume_minimo {
                text
              }
              prazo_resultado {
                text
              }
              temperatura {
                text
              }
              meios_de_coleta {
                text
              }
              metodo {
                text
              }
              realizacao {
                text
              }
              estabilidade_de_amostra___freezer {
                text
              }
              estabilidade_de_amostra___ambiente {
                text
              }
              estabilidade_da_amostra___refrigerada {
                text
              }
            }
          }
          ... on PrismicExamesDataBodyInstrucoes {
            id
            slice_type
            primary {
              preparo {
                richText
              }
              coleta {
                richText
              }
              transporte_e_armazenamento {
                richText
              }
              estabilidade {
                richText
              }
              rejeicao {
                richText
              }
              informacoes_adicionais {
                richText
              }
            }
          }
          ... on PrismicExamesDataBodyInterpretacao {
            id
            slice_type
            primary {
              restricoes {
                text
                richText
              }
              interpretacao {
                richText
              }
            }
          }
          ... on PrismicExamesDataBodyDocumentos {
            id
            slice_type
            items {
              selecao_do_documento {
                document {
                  ... on PrismicDocumento {
                    data {
                      codigo_do_documento {
                        text
                      }
                      anexo_documento {
                        url
                      }
                      nome_do_documento {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

import React from "react";
import { INamesSlice, sliceInterpretacao } from "_types/IExameInterno";
import { PrismicRichText } from "presentation/components";
import { ContainerInterpretation } from "./style";

interface InterpretationProps {
  data: sliceInterpretacao;
  handleHoverSection: ({
    slice,
    action
  }: {
    slice: INamesSlice;
    action: "enter" | "exit";
  }) => void;
}

export function Interpretation({
  data,
  handleHoverSection
}: InterpretationProps): JSX.Element {
  return (
    <ContainerInterpretation
      id="interpretacao"
      onMouseEnter={() =>
        handleHoverSection({ slice: "#interpretacao", action: "enter" })
      }
      onMouseLeave={() =>
        handleHoverSection({ slice: "#interpretacao", action: "exit" })
      }
    >
      <h2 className="interpretation__title">Interpretação do Exame</h2>
      <PrismicRichText
        field={data.primary.interpretacao.richText}
        className="interpretation__text"
        fallback="-"
      />
      <div className="interpretation__relations">
        <strong className="relation__title">Restrições</strong>
        <PrismicRichText
          field={data.primary.restricoes?.richText}
          className="relation__item"
          fallback="-"
        />
      </div>
    </ContainerInterpretation>
  );
}

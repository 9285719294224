import { pxToRem } from "_utils/pxToRem";
import { LayoutGridSpace } from "presentation/style/shared";
import { breakpoints, colors } from "presentation/style/theme";
import styled from "styled-components";

export const ContainerDocuments = styled.section`
  .document__title {
    color: ${colors.neutral[900]};
    font-size: ${pxToRem(23.04)};
    line-height: ${pxToRem(24)};
    margin-bottom: 24px;
  }

  .document__item {
    ${LayoutGridSpace};
    width: 100%;
    border-bottom: 1px solid ${colors.neutral[900]};
    align-items: center;
    padding: 16px;
    text-decoration: none;
    .item__code,
    .item__name {
      font-weight: 300;
      color: ${colors.neutral[700]};
      line-height: ${pxToRem(24)};
    }
    .item__code--title,
    .item__name--title {
      font-weight: 400;
      color: ${colors.neutral[900]};
      font-size: ${pxToRem(16)} !important;
    }
  }
  .document__item--link {
    position: relative;
    .item__circle-icon {
      transition: all 0.3s;
      transition-delay: 0.1s;
      border-radius: 50%;
      display: flex;
      width: fit-content;
      .item__icon {
        margin: auto;
      }
    }
    ::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: ${colors.neutral[800]};
      transition: height 0.25s ease-out;
      z-index: -1;
    }
    :hover {
      ::before {
        height: 100%;
        background-color: #f1e8e5;
      }
      .item__circle-icon {
        background-color: #e3d5cf;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .document__item {
      display: flex;
      justify-content: space-between;
      .item__code,
      .item__name {
        font-size: ${pxToRem(19.2)};
      }
      .item__name {
        flex: 1;
      }
      .item__code {
        display: none;
      }
    }
  }
  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    .document__item {
      grid-template-columns: repeat(5, 1fr);
      .item__code,
      .item__name {
        font-size: ${pxToRem(19.2)};
      }
      .item__name {
        grid-column: 1 / span 4;
      }
      .item__code {
        display: none;
      }
      .item__circle-icon {
        grid-column: 5 / span 1;
        margin: auto;
      }
    }
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    .document__item {
      grid-template-columns: repeat(8, 1fr);
      .item__code,
      .item__name {
        font-size: ${pxToRem(19.2)};
      }
      .item__name {
        grid-column: 1 / span 5;
      }
      .item__code {
        grid-column: 6 / span 2;
      }
      .item__code--title {
        font-size: ${pxToRem(16)};
      }
      .item__circle-icon {
        grid-column: 8 / span 1;
        margin: auto;
      }
    }
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    .document__title {
      font-size: ${pxToRem(25)};
      line-height: ${pxToRem(32)};
    }
    .document__item {
      grid-template-columns: repeat(8, 1fr);
      .item__code,
      .item__name {
        font-size: ${pxToRem(20)};
      }
      .item__name {
        grid-column: 1 / span 5;
      }
      .item__code {
        grid-column: 6 / span 2;
      }
      .item__circle-icon {
        grid-column: 8 / span 1;
        margin: auto;
      }
    }
  }
`;

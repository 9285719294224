import React from "react";
import { INamesSlice, sliceSobre } from "_types/IExameInterno";
import { PrismicRichText } from "presentation/components";
import { ContainerAboutExam } from "./style";

interface AboutExamProps {
  data: sliceSobre;
  handleHoverSection: ({
    slice,
    action
  }: {
    slice: INamesSlice;
    action: "enter" | "exit";
  }) => void;
}

export function AboutExam({
  data,
  handleHoverSection
}: AboutExamProps): JSX.Element {
  const shouldRenderRelatedDiseases = (data.items ?? []).length > 0;
  return (
    <ContainerAboutExam
      id="sobre"
      onMouseEnter={() =>
        handleHoverSection({ slice: "#sobre", action: "enter" })
      }
      onMouseLeave={() =>
        handleHoverSection({ slice: "#sobre", action: "exit" })
      }
    >
      <h2 className="about__title">Sobre o exame</h2>
      <PrismicRichText
        field={data.primary.sobre_o_exame.richText}
        className="about__text"
      />
      {shouldRenderRelatedDiseases && (
        <div className="about__relations">
          <strong className="relation__title">Doenças Relacionadas</strong>
          <p className="relation__item">
            {(data.items ?? [])
              .map(item => item.doencas_relacionadas.text)
              .join(", ")}
          </p>
        </div>
      )}
    </ContainerAboutExam>
  );
}

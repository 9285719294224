import styled from "styled-components";
import { LayoutGridSpace } from "presentation/style/shared";
import { breakpoints, colors } from "presentation/style/theme";
import { pxToRem } from "_utils/pxToRem";

export const ContainerInstructions = styled.section`
  ${LayoutGridSpace};
  width: 100%;
  row-gap: 24px;
  .instruction__title {
    font-weight: 400;
    color: ${colors.neutral[900]};
    line-height: ${pxToRem(24)};
  }
  .instruction__item {
    display: flex;
    flex-direction: column;
    .item__title {
      font-weight: 600;
      color: ${colors.neutral[900]};
      line-height: ${pxToRem(24)};
      margin-bottom: 8px;
    }
    > .item__content {
      color: ${colors.neutral[900]};
      font-size: ${pxToRem(16)};
      line-height: ${pxToRem(24)};
    }
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    .instruction__title {
      font-size: ${pxToRem(20.25)};
      grid-column: 1 / span 2;
    }
    .instruction__item {
      grid-column: 1 / span 2;
      .item__title {
        font-size: ${pxToRem(18)};
      }
    }
  }
  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    grid-template-columns: repeat(5, 1fr);
    .instruction__title {
      grid-column: 1 / span 5;
      font-size: ${pxToRem(23.04)};
    }
    .instruction__item {
      grid-column: 1 / span 5;
      .item__title {
        font-size: ${pxToRem(18)};
      }
    }
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    grid-template-columns: repeat(8, 1fr);
    .instruction__title {
      font-size: ${pxToRem(23.04)};
      grid-column: 1 / span 8;
    }
    .instruction__item {
      .item__title {
        font-size: ${pxToRem(19.2)};
      }
    }
    .instruction__item--preparo,
    .instruction__item--transporte {
      grid-column: 1 / span 4;
    }

    .instruction__item--coleta,
    .instruction__item--estabilidade {
      grid-column: 5 / span 4;
    }

    .instruction__item--rejeicao,
    .instruction__item--informacoes {
      grid-column: 1 / span 8;
    }
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    grid-template-columns: repeat(8, 1fr);
    .instruction__title {
      font-size: ${pxToRem(25)};
      line-height: ${pxToRem(32)};
      grid-column: 1 / span 8;
    }
    .instruction__item {
      .item__title {
        font-size: ${pxToRem(20)};
      }
    }
    .instruction__item--preparo,
    .instruction__item--transporte {
      grid-column: 1 / span 4;
    }

    .instruction__item--coleta,
    .instruction__item--estabilidade {
      grid-column: 5 / span 4;
    }

    .instruction__item--rejeicao,
    .instruction__item--informacoes {
      grid-column: 1 / span 8;
    }
  }
`;

import { pxToRem } from "_utils/pxToRem";
import { LayoutGrid } from "presentation/style/shared";
import { colors, breakpoints } from "presentation/style/theme";
import styled from "styled-components";

export const ContainerDataExam = styled.section`
  ${LayoutGrid}
  row-gap: 16px;
  margin-top: 16px !important;
  .data__infos {
    .data__strong {
      color: ${colors.neutral[900]};
    }
    .data__weak {
      color: ${colors.neutral[700]};
    }
    .data__strong,
    .data__weak {
      font-size: ${pxToRem(16)};
      line-height: ${pxToRem(24)};
    }
  }
  .data__button {
    width: 100%;
    text-decoration: none;
    .button__item {
      width: 100%;
      svg {
        scale: 2;
      }
    }
  }
  .data__line {
    height: 1px;
    border: none;
    background-color: ${colors.neutral[900]};
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    .data__infos,
    .data__button,
    .data__line {
      grid-column: 1 / span 2;
    }
    .data__infos--last {
      grid-row: 2 span / 1;
    }
  }
  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    .data__infos {
      grid-column: 2 / span 3;
    }
    .data__button {
      grid-column: 5 / span 3;
    }
    .data__button--first {
      grid-row: 1 / span 1;
    }
    .data__line {
      grid-column: 2 / span 6;
    }
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    .data__infos {
      grid-column: 2 / span 6;
    }
    .data__button {
      grid-column: 8 / span 4;
    }
    .data__button--first {
      grid-row: 1 / span 1;
    }
    .data__line {
      grid-column: 2 / span 10;
    }
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    .data__infos {
      grid-column: 2 / span 6;
    }
    .data__button {
      grid-column: 10 / span 2;
    }
    .data__button--first {
      grid-row: 1 / span 1;
    }
    .data__line {
      grid-column: 2 / span 10;
    }
  }
`;

import styled, { css } from "styled-components";
import { pxToRem } from "_utils/pxToRem";
import { LayoutGrid } from "presentation/style/shared";
import { colors, breakpoints } from "presentation/style/theme";

const modifier = {
  containerDinamicTop: {
    initialStyle: css`
      > .top__name {
        margin-top: 32px;
        color: ${colors.neutral[900]};
      }
      @media screen and (max-width: 767px) {
        .top__view-all,
        .top__icons,
        .top__name,
        .top__labels {
          grid-column: 1 / span 2;
        }
        .top__name {
          margin-top: 24px;
          font-size: ${pxToRem(28.83)};
          line-height: ${pxToRem(32)};
        }
        .top__labels {
          grid-row: 4 / span 1;
          margin-top: 8px;
        }
      }
      @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
        .top__icons {
          grid-column: 2 / span 2;
        }
        .top__labels {
          grid-column: 4 / span 4;
        }
        .top__name {
          font-size: ${pxToRem(39.81)};
          line-height: ${pxToRem(48)};
          grid-column: 2 / span 6;
        }
      }
      @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
        .top__icons {
          grid-column: 2 / span 4;
        }
        .top__labels {
          grid-column: 8 / span 4;
        }
        .top__name {
          font-size: ${pxToRem(39.81)};
          line-height: ${pxToRem(48)};
          grid-column: 2 / span 10;
        }
      }
      @media screen and (min-width: ${breakpoints.xl}) {
        .top__name {
          grid-column: 2 / span 10;
          font-size: ${pxToRem(48)};
          line-height: ${pxToRem(56)};
        }
      }
    `,
    customStyle: css`
      .top__line {
        margin-top: 16px;
      }
      @media screen and (max-width: 767px) {
        .top__view-all,
        .top__icons {
          display: none;
        }
        .top__name,
        .top__labels {
          grid-column: 1 / span 2;
        }
        .top__labels {
          grid-row: 2 / span 1;
          margin-top: 8px;
        }
      }
      @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
        .top__icons {
          grid-column: 2 / span 1;
          align-self: flex-start;
        }
        .top__labels,
        .top__name {
          grid-column: 3 / span 5;
        }
        .top__name {
          font-size: ${pxToRem(19.2)};
          line-height: ${pxToRem(24)};
          font-weight: 400;
        }
        .top__labels {
          justify-content: flex-start;
          grid-row: 3 / span 1;
          margin-top: 8px;
        }
      }
      @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
        height: 290px;
        /* verificar aqui */
        .top__icons {
          grid-column: 2 / span 2;
          align-self: flex-start;
        }
        .top__labels,
        .top__name {
          grid-column: 4 / span 8;
        }
        .top__name {
          font-weight: 400;
          font-size: ${pxToRem(20)};
          line-height: ${pxToRem(24)};
        }
        .top__labels {
          justify-content: flex-start;
          grid-row: 3 / span 1;
          margin-top: 8px;
        }
      }
      @media screen and (min-width: ${breakpoints.xl}) {
        height: 290px;
        .top__name {
          font-size: ${pxToRem(20)};
          font-weight: 400;
          line-height: ${pxToRem(24)};
          grid-row: 2 / span 1;
          grid-column: 3 / span 7;
        }
        .top__line {
          margin-bottom: 16px;
        }
      }
    `
  }
};

export const ContainerDinamicTop = styled.div<{
  isInitialStyle: boolean;
}>`
  ${LayoutGrid};
  position: sticky;
  top: 0;
  ::after {
    background-color: ${colors.complementary[100]};
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  z-index: 2;
  .top__view-all {
    color: ${colors.neutral[700]};
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(24)};
    font-weight: 600;
    display: flex;
    align-items: center;
    column-gap: 16px;
    text-decoration: none;
    width: fit-content;
    margin-bottom: 40px;
  }
  .top__icons {
    flex-wrap: wrap;
    .icon__specialtie {
      svg {
        width: 25px;
        height: 25px;
        path {
          fill: ${colors.neutral[900]};
        }
      }
    }
  }
  .top__icons,
  .top__labels {
    display: flex;
    column-gap: 16px;
    align-self: center;
  }
  .top__labels {
    justify-content: flex-end;
    .label__item {
      color: ${colors.neutral[700]};
      line-height: ${pxToRem(16)};
      font-weight: 300;
    }
  }
  .top__name {
    transition: all 0.3s ease;
  }

  .top__line {
    height: 1px;
    border: none;
    background-color: ${colors.neutral[900]};
    width: 100%;
    margin-top: 32px;
  }
  ${({ isInitialStyle }) =>
    isInitialStyle
      ? modifier.containerDinamicTop.initialStyle
      : modifier.containerDinamicTop.customStyle}
  @media screen and (max-width: 767px) {
    padding-top: 104px;
    .top__line {
      grid-column: 1 / span 2;
    }
    .top__labels {
      justify-content: flex-start;
      .label__item {
        font-size: ${pxToRem(14.22)};
      }
    }
  }
  @media (min-width: ${breakpoints.md}) and (max-width: 1023px) {
    padding-top: 104px;
    .top__view-all {
      grid-column: 2 / span 6;
    }
    .top__labels .label__item {
      font-size: ${pxToRem(13.33)};
    }
    .top__line {
      grid-column: 2 / span 6;
    }
  }
  @media (min-width: ${breakpoints.lg}) and (max-width: 1919px) {
    padding-top: 128px;
    .top__view-all {
      grid-column: 2 / span 10;
    }
    .top__labels .label__item {
      font-size: ${pxToRem(13.33)};
    }
    .top__line {
      grid-column: 2 / span 10;
    }
  }
  @media screen and (min-width: ${breakpoints.xl}) {
    width: 100%;
    padding-top: 128px;
    .top__view-all {
      grid-column: 2 / span 10;
    }
    .top__icons {
      grid-column: 2 / span 1;
    }
    .top__labels {
      grid-column: 10 / span 2;
      .label__item {
        font-size: ${pxToRem(12.8)};
      }
    }
    .top__line {
      grid-column: 2 / span 10;
    }
  }
`;

import React from "react";
import type { locationProps } from "_types/ICommon";
import { INamesSlice } from "_types/IExameInterno";

export function useInternalExams({ location }: { location: locationProps }) {
  const dinamicTopRef = React.useRef<HTMLDivElement>(null);
  const [hashSelected, setHashSelected] = React.useState<string>("");
  const [sizeDinamictop, setSizeDinamictop] = React.useState(0);

  function getUrlSVG(value: string) {
    const position: number = value.indexOf(".svg") ?? 0;
    const url = value?.substring(0, position + 4);
    return url;
  }

  const [isInitialStyle, setIsInitialStyle] = React.useState(true);
  function changedStatePage() {
    if (window?.scrollY === 0) {
      if (!isInitialStyle) {
        setIsInitialStyle(true);
      }
    }
    if (window?.scrollY >= 128) {
      if (isInitialStyle) {
        setIsInitialStyle(false);
      }
    }
  }

  const onGetHeightDinamicTop = React.useCallback(() => {
    const dinamicTopHeight = dinamicTopRef?.current?.offsetHeight;
    setSizeDinamictop(dinamicTopHeight ?? 128);
  }, []);

  function onTokeIdLink(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const target = event.currentTarget;
    const href = target.getAttribute("href");
    if (href) {
      const divId = href.substring(1);
      const lastCharacter = location.pathname.charAt(
        location.pathname.length - 1
      );
      const formatedPathname =
        lastCharacter === "/"
          ? location.pathname.substring(0, location.pathname.length - 1)
          : location.pathname;
      setHashSelected(`#${divId}`);
      window.history.pushState(null, "", `${formatedPathname}#${divId}`);
      const divElement = document.getElementById(divId);
      if (divElement) {
        const topOffset = divElement.offsetTop - 100;
        window.scrollTo({
          top: topOffset - sizeDinamictop + 30,
          behavior: "smooth"
        });
      }
    }
  }

  React.useEffect(() => {
    const isExistdinamicTop =
      dinamicTopRef?.current !== undefined && dinamicTopRef?.current !== null;
    if (isExistdinamicTop) {
      onGetHeightDinamicTop();
    }
    changedStatePage();
    window.addEventListener("scroll", changedStatePage);
  }, [isInitialStyle]);

  // DESATIVAR FUNCAO NATIVA DE SCROLL QUANDO EXISTE HASH
  //   React.useEffect(() => {
  //     function handleHashLoad(event: Event) {
  //       const existHash = location.hash !== "";
  //       if (existHash) {
  //         event.preventDefault();
  //         const divId = location.hash.substring(1);
  //         const divElement = document.getElementById(divId);
  //         if (divElement) {
  //           const topOffset = divElement.offsetTop - 500;
  //           window.scrollTo({
  //             top: topOffset - sizeDinamictop + 30,
  //             behavior: "smooth"
  //           });
  //         }
  //       }
  //     }
  //     window.addEventListener("load", handleHashLoad);
  //     return () => {
  //       window.removeEventListener("load", handleHashLoad);
  //     };
  //   }, []);

  const links = [
    {
      id: "#sobre",
      value: "sobre",
      label: "Sobre"
    },
    {
      id: "#producao",
      value: "producao",
      label: "Produção"
    },
    {
      id: "#instrucoes",
      value: "instrucoes",
      label: "Instruções"
    },
    {
      id: "#interpretacao",
      value: "interpretacao",
      label: "Interpretação"
    },
    {
      id: "#documentos",
      value: "documentos",
      label: "Documentos"
    }
  ];

  function checkCurrentHash(value: string): string {
    if (value === hashSelected) {
      return "anchor__item--selected";
    }
    if (value === location?.hash && hashSelected === "") {
      return "anchor__item--selected";
    }
    return "";
  }

  function handleHoverSection({
    slice,
    action
  }: {
    slice: INamesSlice;
    action: "enter" | "exit";
  }): void {
    const isEnter = action === "enter";
    if (isEnter) {
      document.getElementById(slice)?.classList.add("anchor__item--selected");
    } else {
      document
        .getElementById(slice)
        ?.classList.remove("anchor__item--selected");
    }
  }

  return {
    getUrlSVG,
    onTokeIdLink,
    checkCurrentHash,
    handleHoverSection,
    isInitialStyle,
    links,
    dinamicTopRef
  };
}

import React from "react";
import { INamesSlice, sliceProducao } from "_types/IExameInterno";
import { ContainerProduction } from "./style";

interface ProductionProps {
  data: sliceProducao;
  handleHoverSection: ({
    slice,
    action
  }: {
    slice: INamesSlice;
    action: "enter" | "exit";
  }) => void;
}

export function Production({
  data,
  handleHoverSection
}: ProductionProps): JSX.Element {
  function formatLabel({ value }: { value?: string }): string {
    return value || "- ";
  }
  return (
    <ContainerProduction
      id="producao"
      onMouseEnter={() =>
        handleHoverSection({ slice: "#producao", action: "enter" })
      }
      onMouseLeave={() =>
        handleHoverSection({ slice: "#producao", action: "exit" })
      }
    >
      <h3 className="production__title">Produção</h3>
      <div className="production__item production__item--material">
        <strong className="item__title">Material</strong>
        <p className="item__content">
          {formatLabel({ value: data?.primary.material1.text })}
        </p>
      </div>
      <div className="production__item production__item--meios">
        <strong className="item__title">Meio(s) de Coleta</strong>
        <p className="item__content">
          {formatLabel({ value: data?.primary.meios_de_coleta.text })}
        </p>
      </div>
      <div className="production__item production__item--temperatura">
        <strong className="item__title">Temperatura</strong>
        <p className="item__content">
          {formatLabel({ value: data?.primary.temperatura.text })}
        </p>
      </div>
      <div className="production__item production__item--volume">
        <strong className="item__title">Volume Mínimo</strong>
        <p className="item__content">
          {formatLabel({ value: data?.primary.volume_minimo.text })}
        </p>
      </div>
      <div className="production__item production__item--metodo">
        <strong className="item__title">Método</strong>
        <p className="item__content">
          {formatLabel({ value: data?.primary.metodo.text })}
        </p>
      </div>
      <div className="production__item production__item--estabilidade">
        <strong className="item__title">Estabilidade da Amostra</strong>
        <p className="item__content item__content--line">
          <span>Ambiente</span>
          <span>
            {formatLabel({
              value: data?.primary.estabilidade_de_amostra___ambiente.text
            })}
          </span>
        </p>
        <p className="item__content item__content--line">
          <span>Freezer</span>
          <span>
            {formatLabel({
              value: data?.primary.estabilidade_de_amostra___freezer.text
            })}
          </span>
        </p>
        <p className="item__content item__content--line">
          <span>Refrigerada</span>
          <span>
            {formatLabel({
              value: data?.primary.estabilidade_da_amostra___refrigerada.text
            })}
          </span>
        </p>
      </div>
      <div className="production__item production__item--prazo">
        <strong className="item__title">Prazo de Resultado*</strong>
        <p className="item__content">
          {formatLabel({ value: data.primary.prazo_resultado.text })}
        </p>
        <p className="item__small">
          *O prazo tem início a partir do momento que o material chega em nossa
          área técnica
        </p>
      </div>
      <div className="production__item production__item--realizacao">
        <strong className="item__title">Realização</strong>
        <p className="item__content">
          {formatLabel({ value: data.primary.realizacao.text })}
        </p>
      </div>
    </ContainerProduction>
  );
}

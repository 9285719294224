import React from "react";
import { Button } from "presentation/components";
import { DataExamProps } from "_types/IInternalExams";
import { ContainerDataExam } from "./style";

export function DataExam({ data }: DataExamProps): JSX.Element {
  const shouldRenderSynonyms = (data?.sinonimos ?? [])?.length > 0;
  const shouldRenderSpecialties = (data?.topDataSpecialties ?? [])?.length > 0;
  const shouldRenderButtonInfoExam = data?.urlInformacaoExame !== null;
  const shouldRenderButtonLaudo = data?.urlLaudo !== null;

  return (
    <ContainerDataExam>
      {shouldRenderSynonyms && (
        <div className="data__infos">
          <strong className="data__strong">Sinônimos</strong>
          <p className="data__weak">{data?.sinonimos.join(", ")} </p>
        </div>
      )}
      {shouldRenderButtonInfoExam && (
        <a
          href={data.urlInformacaoExame ?? "#"}
          className="data__button"
          target="_blank"
          rel="noreferrer"
          download
        >
          <Button
            className="button__item"
            iconName="DownloadIcon"
            title="Download Página"
          />
        </a>
      )}
      {shouldRenderSpecialties && (
        <div className="data__infos data__infos--last">
          <strong className="data__strong">Especialidades</strong>
          <p className="data__weak">
            {data?.topDataSpecialties
              .map(item => item.nome_da_especialidade)
              .join(" | ")}
          </p>
        </div>
      )}
      {shouldRenderButtonLaudo && (
        <a
          href={data.urlLaudo ?? "#"}
          className={`data__button ${
            !shouldRenderButtonInfoExam ? "data__button--first" : ""
          } `}
          target="_blank"
          rel="noreferrer"
          download
        >
          <Button
            className="button__item"
            iconName="DownloadIcon"
            title="Máscara do Laudo"
          />
        </a>
      )}
      <hr className="data__line" />
    </ContainerDataExam>
  );
}

import React from "react";
import { INamesSlice, sliceInstrucoes } from "_types/IExameInterno";
import { PrismicRichText } from "presentation/components";
import { ContainerInstructions } from "./style";

interface InstructionsProps {
  data: sliceInstrucoes;
  handleHoverSection: ({
    slice,
    action
  }: {
    slice: INamesSlice;
    action: "enter" | "exit";
  }) => void;
}

export function Instructions({
  data,
  handleHoverSection
}: InstructionsProps): JSX.Element {
  return (
    <ContainerInstructions
      id="instrucoes"
      onMouseEnter={() =>
        handleHoverSection({ slice: "#instrucoes", action: "enter" })
      }
      onMouseLeave={() =>
        handleHoverSection({ slice: "#instrucoes", action: "exit" })
      }
    >
      <h3 className="instruction__title">Instruções</h3>
      <div className="instruction__item instruction__item--preparo">
        <strong className="item__title">Preparo</strong>
        <PrismicRichText
          field={data.primary.preparo.richText}
          className="item__content"
          fallback="-"
        />
      </div>
      <div className="instruction__item instruction__item--coleta">
        <strong className="item__title">Coleta</strong>
        <PrismicRichText
          field={data.primary.coleta.richText}
          className="item__content"
          fallback="-"
        />
      </div>
      <div className="instruction__item instruction__item--transporte">
        <strong className="item__title">Transporte e Armazenamento</strong>
        <PrismicRichText
          field={data.primary.transporte_e_armazenamento.richText}
          className="item__content"
          fallback="-"
        />
      </div>
      <div className="instruction__item instruction__item--estabilidade">
        <strong className="item__title">Estabilidade</strong>
        <PrismicRichText
          field={data.primary.estabilidade.richText}
          className="item__content"
          fallback="-"
        />
      </div>
      <div className="instruction__item instruction__item--rejeicao">
        <strong className="item__title">Rejeição</strong>
        <PrismicRichText
          field={data.primary.rejeicao.richText}
          className="item__content"
          fallback="-"
        />
      </div>
      <div className="instruction__item instruction__item--informacoes">
        <strong className="item__title">Informações Adicionais</strong>
        <PrismicRichText
          field={data.primary.informacoes_adicionais.richText}
          className="item__content"
          fallback="-"
        />
      </div>
    </ContainerInstructions>
  );
}

import React from "react";
import { Link } from "gatsby";
import { ArrowIcon } from "presentation/assets/icons/ArrowIcon";
import { DinamicTopProps } from "_types/IInternalExams";
import { ReactSVG } from "react-svg";
import { ContainerDinamicTop } from "./style";

export function DinamicTop({
  isInitialStyle,
  data,
  urlViewAll,
  iconsSpecialties,
  dinamicTopRef
}: DinamicTopProps): JSX.Element {
  const shouldRenderTuss = !!data?.primary?.codigo_tuss?.text;
  const shouldRenderCodesollutio = !!data?.primary?.codigo_sollutio?.text;
  return (
    <ContainerDinamicTop isInitialStyle={isInitialStyle} ref={dinamicTopRef}>
      <Link to={`/${urlViewAll ?? "exames"}`} className="top__view-all">
        <ArrowIcon side="right" />
        Ver Todos os exames
      </Link>
      <div className="top__icons">
        {iconsSpecialties.map((item, index) => (
          <ReactSVG
            key={Number(index)}
            src={item.url}
            className="icon__specialtie"
          />
        ))}
      </div>
      <div className="top__labels">
        {shouldRenderCodesollutio && (
          <p className="label__item">
            Sollutio: {data?.primary?.codigo_sollutio?.text}
          </p>
        )}
        {shouldRenderTuss && (
          <p className="label__item">
            TUSS: {data?.primary?.codigo_tuss?.text}
          </p>
        )}
      </div>
      <h1 className="top__name">{data?.primary?.nome_do_exame?.text}</h1>
      <hr className="top__line" />
    </ContainerDinamicTop>
  );
}

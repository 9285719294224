import React from "react";
import { InternalExamsProps } from "_types/IInternalExams";
import { CTASection, QualityWarranty } from "presentation/components";
import {
  ExameInternoSliceTypeProps,
  INamesSlice,
  sliceDocumentos,
  sliceHero,
  sliceInstrucoes,
  sliceInterpretacao,
  sliceProducao,
  sliceSobre
} from "_types/IExameInterno";
import { InternaExamContainer, RootInternalExam } from "./style";
import { DinamicTop } from "./partials/DinamicTop";
import { DataExam } from "./partials/DataExam";
import { AboutExam } from "./partials/SliceZone/AboutExam";
import { Production } from "./partials/SliceZone/Production";
import { Instructions } from "./partials/SliceZone/Instructions";
import { Interpretation } from "./partials/SliceZone/Interpretation";
import { Documents } from "./partials/SliceZone/Documents";
import { useInternalExams } from "./useInternalExams";

const typesSlices = {
  sobre: AboutExam,
  hero: null,
  producao: Production,
  instrucoes: Instructions,
  interpretacao: Interpretation,
  documentos: Documents
};

function RenderDivider({ index }: { index: number }) {
  return index > 0 ? <hr className="divider__line" /> : null;
}

function RenderSliceZone({
  sliceZone,
  handleHoverSection
}: {
  sliceZone: ExameInternoSliceTypeProps[];
  handleHoverSection: ({
    slice,
    action
  }: {
    slice: INamesSlice;
    action: "enter" | "exit";
  }) => void;
}): JSX.Element | null {
  const getSliceType = (sliceZone ?? []).map((item, index) => {
    const Component = typesSlices[item?.slice_type];
    const getData = item;
    if (!Component) {
      return null;
    }
    if (item.slice_type === "sobre") {
      return (
        <React.Fragment key={String(index ?? "")}>
          <AboutExam
            data={getData as sliceSobre}
            handleHoverSection={handleHoverSection}
          />
          <RenderDivider index={index} />
        </React.Fragment>
      );
    }
    if (item.slice_type === "producao") {
      return (
        <React.Fragment key={String(index ?? "")}>
          <Production
            data={getData as sliceProducao}
            handleHoverSection={handleHoverSection}
          />
          <RenderDivider index={index} />
        </React.Fragment>
      );
    }
    if (item.slice_type === "instrucoes") {
      return (
        <React.Fragment key={String(index ?? "")}>
          <Instructions
            data={getData as sliceInstrucoes}
            handleHoverSection={handleHoverSection}
          />
          <RenderDivider index={index} />
        </React.Fragment>
      );
    }
    if (item.slice_type === "interpretacao") {
      return (
        <Interpretation
          key={String(index ?? "")}
          data={getData as sliceInterpretacao}
          handleHoverSection={handleHoverSection}
        />
      );
    }
    if (item.slice_type === "documentos") {
      return (
        <React.Fragment key={String(index ?? "")}>
          <Documents
            data={getData as sliceDocumentos}
            handleHoverSection={handleHoverSection}
          />
          <RenderDivider index={index} />
        </React.Fragment>
      );
    }
    return null;
  });
  return <>{getSliceType}</>;
}

function InternalExams({
  ctaContato,
  sectionQualityWarranty,
  dataPage,
  urlViewAll,
  topDataSpecialties,
  location
}: InternalExamsProps) {
  const {
    onTokeIdLink,
    getUrlSVG,
    checkCurrentHash,
    handleHoverSection,
    links,
    isInitialStyle,
    dinamicTopRef
  } = useInternalExams({ location });
  const dataDinamicTop = dataPage.data.body.filter(
    item => item.slice_type === "hero"
  )[0] as sliceHero;

  const dataExamTop = {
    sinonimos: dataDinamicTop.items.map(item => item.sinonimo.text),
    especialdidade: [],
    urlInformacaoExame: dataDinamicTop.primary.informacoes_do_exame.url,
    urlLaudo: dataDinamicTop.primary.documento_mascara_do_laudo.url,
    topDataSpecialties
  };

  const iconsSpecialties = (topDataSpecialties ?? []).map(item => ({
    url: getUrlSVG(item.icone_da_especialidade ?? "")
  }));

  const getAsideLinks = links.filter(item =>
    dataPage.data.body
      .filter(dataItem => dataItem.slice_type !== "hero")
      .some(sameItem => sameItem.slice_type === item.value)
  );

  return (
    <RootInternalExam>
      <InternaExamContainer>
        <DinamicTop
          urlViewAll={urlViewAll}
          isInitialStyle={isInitialStyle}
          data={dataDinamicTop}
          iconsSpecialties={iconsSpecialties}
          dinamicTopRef={dinamicTopRef}
        />
        <DataExam data={dataExamTop} />
        <section className="exam__breakpage">
          <aside className="exam__anchors">
            {(getAsideLinks ?? []).map(item => (
              <a
                key={item.id}
                href={item.id}
                className={`anchor__item ${checkCurrentHash(item.id)} `}
                onClick={onTokeIdLink}
                id={item.id}
              >
                {item.label}
              </a>
            ))}
          </aside>
          <div className="exam__slice">
            <RenderSliceZone
              handleHoverSection={handleHoverSection}
              sliceZone={dataPage.data.body.filter(
                item => item.slice_type !== "hero"
              )}
            />
          </div>
        </section>
      </InternaExamContainer>
      <CTASection data={ctaContato} className="cta__section" />
      <QualityWarranty data={sectionQualityWarranty} />
    </RootInternalExam>
  );
}

export default InternalExams;
